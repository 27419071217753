// MaterialUI Imports
import { Stack, Typography } from "@mui/material";
import { util } from "@wire-fx/core-ui";

// ==============================|| Utility FUnction for  Bank Details ||============================== //
export const reformatDetailsForRendering = (data) => {
  let formattedDetails = {};

  switch (data.InfoType) {
    case "ach":
      formattedDetails.ach = {
        aba: data.aba,
        type: data.achType,
        account: data.account,
      };
      break;
    case "visab2b":
      formattedDetails.visab2b = {
        bic: data.bic,
        account: data.account,
        address: {
          street1: data.street1,
          street2: data.street2,
          city: data.city,
          country: data.country,
          regionCode: data.state,
          postalCode: data.postalCode,
        },
      };
      break;
    case "wire":
      formattedDetails.wire = {
        bank: {
          name: data.bankName,
          swift: data.swift,
          address: [
            data.bankAddressLine1,
            data.bankAddressLine2,
            data.bankAddressLine3,
          ].filter((line) => line),
        },
        account: data.account,
        address: [
          data.addressLine1,
          data.addressLine2,
          data.addressLine3,
        ].filter((line) => line),
      };
      break;
    case "interLedger":
      formattedDetails.interledger = {
        paymentPointer: data.paymentPointer,
        address: {
          street1: data.street1,
          street2: data.street2,
          city: data.city,
          country: data.country,
          regionCode: data.state,
        },
      };
      break;
    default:
      // Handle default case if necessary
      break;
  }

  return formattedDetails;
};

// ==============================|| RENDER Bank Details ||============================== //
export const renderBankDetails = (details, receiverName) => {
  let detailType;

  if (details?.ach || details?.network === "ach") {
    detailType = "ach";
  } else if (details?.visab2b) {
    detailType = "visab2b";
  } else if (details?.interledger) {
    detailType = "interledger";
  } else if (details?.wire) {
    detailType = "wire";
  }
  switch (detailType) {
    case "ach":
      return (
        <Stack spacing={2}>
          <Typography variant="h4">{receiverName} Bank Details</Typography>
          <Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="subtitle1">Aba: </Typography>
              <Typography variant="body2">{details.ach?.aba}</Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="subtitle1">Bank Account Type:</Typography>
              <Typography variant="body2">{details.ach?.type}</Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="subtitle1">Account Number:</Typography>
              <Typography variant="body2">{details.ach?.account}</Typography>
            </Stack>
          </Stack>
        </Stack>
      );

    case "wire":
      return (
        <Stack spacing={2}>
          <Typography variant="h4">
            {receiverName} Bank Details (Wire Transfer)
          </Typography>
          <Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="subtitle1">Bank Name:</Typography>
              <Typography variant="body2">{details.wire.bank.name}</Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="subtitle1">SWIFT:</Typography>
              <Typography variant="body2">{details.wire.bank.swift}</Typography>
            </Stack>
            {details.wire.bank.address.map((addressLine, index) => (
              <Stack
                key={index}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Typography variant="subtitle1">
                  Bank Address {index + 1}:
                </Typography>
                <Typography variant="body2">{addressLine}</Typography>
              </Stack>
            ))}
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="subtitle1">Account Number:</Typography>
              <Typography variant="body2">{details.wire.account}</Typography>
            </Stack>
            {details.wire.address.map((addressLine, index) => (
              <Stack
                key={index}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Typography variant="subtitle1">
                  Recipient Address {index + 1}:
                </Typography>
                <Typography variant="body2">{addressLine}</Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
      );

    case "visab2b":
      const visab2bCountryAndRegion = util.country.getCountryAndRegion(
        details.visab2b?.address?.country,
        details.visab2b?.address?.regionCode,
      );

      return (
        <Stack spacing={2}>
          <Typography variant="h4">{receiverName} Bank Details</Typography>
          <Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="subtitle1">BIC: </Typography>
              <Typography variant="body2">{details.visab2b?.bic}</Typography>
            </Stack>
            {details.visab2b.account && (
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="subtitle1">Account: </Typography>
                <Typography variant="body2">
                  {details.visab2b?.account}
                </Typography>
              </Stack>
            )}
            {details.visab2b.iban && (
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="subtitle1">Iban: </Typography>
                <Typography variant="body2">{details.visab2b?.iban}</Typography>
              </Stack>
            )}
            {details.visab2b.bank && (
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="subtitle1">Bank Code: </Typography>
                <Typography variant="body2">
                  {details.visab2b?.bank}
                </Typography>
              </Stack>
            )}
            {details.visab2b.branch && (
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="subtitle1">Branch: </Typography>
                <Typography variant="body2">
                  {details.visab2b?.branch}
                </Typography>
              </Stack>
            )}
            {details.visab2b.nationalId && (
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="subtitle1">National ID: </Typography>
                <Typography variant="body2">
                  {details.visab2b?.nationalId}
                </Typography>
              </Stack>
            )}
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="subtitle1">Street Address:</Typography>
              <Typography variant="body2">
                {details.visab2b?.address?.street1}
              </Typography>
            </Stack>
            {details.visab2b.address.street2 && (
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="subtitle1">Street Address 2:</Typography>
                <Typography variant="body2">
                  {details.visab2b?.address?.street2}
                </Typography>
              </Stack>
            )}
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="subtitle1">City:</Typography>
              <Typography variant="body2">
                {details.visab2b?.address?.city}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="subtitle1">Country:</Typography>
              <Typography variant="body2">
                {visab2bCountryAndRegion?.label}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="subtitle1">State:</Typography>
              <Typography variant="body2">
                {visab2bCountryAndRegion?.region?.label}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="subtitle1">Zip Code:</Typography>
              <Typography variant="body2">
                {details.visab2b?.address?.postalCode}
              </Typography>
            </Stack>
            {details.visab2b?.phone && (
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="subtitle1">Phone Number:</Typography>
                <Typography variant="body2">
                  {details.visab2b?.phone}
                </Typography>
              </Stack>
            )}
            {details.visab2b?.contact && (
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="subtitle1">Contact:</Typography>
                <Typography variant="body2">
                  {details.visab2b?.contact}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      );
    case "interledger":
      const interledgerCountryAndRegion = util.country.getCountryAndRegion(
        details.interledger?.address?.country,
        details.interledger?.address?.regionCode,
      );

      return (
        <Stack spacing={2}>
          <Typography variant="h4">
            {receiverName} Interledger Details
          </Typography>
          <Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="subtitle1">Payment Pointer:</Typography>
              <Typography variant="body2">
                {details.interledger?.paymentPointer}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="subtitle1">Street Address: </Typography>
              <Typography variant="body2">
                {details.interledger?.address?.street1}
              </Typography>
            </Stack>
            {details.interledger.address.street2 && (
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="subtitle1">Street Address 2: </Typography>
                <Typography variant="body2">
                  {details.interledger?.address?.street2}
                </Typography>
              </Stack>
            )}
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="subtitle1">City:</Typography>
              <Typography variant="body2">
                {details.interledger?.address?.city}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="subtitle1">Country:</Typography>
              <Typography variant="body2">
                {interledgerCountryAndRegion?.label}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="subtitle1">State:</Typography>
              <Typography variant="body2">
                {interledgerCountryAndRegion?.region?.label}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      );

    default:
      return null;
  }
};
